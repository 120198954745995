import { api, i18n } from "@/config"
import { handleFailedResponse } from "@/helpers/common"
import { showSuccessToast } from "@/helpers/toasts"
import { map, compact } from "lodash-es"

const buildParams = ({ hours, ...rest }) => ({ ...rest, hours: compact(map(hours, "value")) })

export default ({ baseURI }) => ({
  FETCH_ITEM: async ({ commit }, id) => {
    try {
      const { data } = await api.get(`${baseURI}/${id}`)
      commit("SET_ITEM", data.data)
    } catch (e) {
      handleFailedResponse(e)
    }
  },

  CREATE_ITEM: async ({ commit }, params) => {
    try {
      const { data } = await api.post(baseURI, { time_slot: buildParams(params) })
      commit("SET_ITEM", data.data)
      showSuccessToast({ text: i18n.t("company_system.basic_settings.time_slot_created") })
    } catch (e) {
      handleFailedResponse(e)
      throw e
    }
  },

  UPDATE_ITEM: async ({ commit }, { id, ...params }) => {
    try {
      const { data } = await api.put(`${baseURI}/${id}`, { time_slot: buildParams(params) })
      commit("SET_ITEM", data.data)
      showSuccessToast({ text: i18n.t("company_system.basic_settings.time_slot_updated") })
    } catch (e) {
      handleFailedResponse(e)
      throw e
    }
  }
})

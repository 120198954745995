<template lang="pug">
  .car-class-settings-form
    .title
      span {{ $t("company_system.rate_plan_settings.rate_plan.details.car_class_settings.title") }}
    .form
      .form-row
        template(v-if="isEdit")
          AppIconButton.clear(
            title="company_system.rate_plan_settings.rate_plan.details.car_class_settings.reset"
            icon="trashXMark"
            :use-app-icon="true"
            @click="clearAll"
          )
        template(v-else)
          .form-row-label
            FormFieldLabel(
              :title="$t('company_system.rate_plan_settings.rate_plan.details.car_class_settings.basic_charge')"
              required
            )
          .form-row-field
            .radio.flex-row
              AppRadioButton(
                :disabled="isEdit"
                :value="usePriceTable"
                @change="switchBasicChargeMode"
              )
              .label
                span {{ $t('company_system.rate_plan_settings.rate_plan.details.car_class_settings.select_from_created_price_table') }}
            .radio.flex-row
              AppRadioButton(
                :disabled="isEdit"
                :value="!usePriceTable"
                @change="switchBasicChargeMode"
              )
              .label
                span {{ $t('company_system.rate_plan_settings.rate_plan.details.car_class_settings.create_new') }}
        PriceTable(
          :key="'basic-table'"
          :price-table="pricesSetting.basic_table"
          :use-price-table="usePriceTable"
          :price-tables="priceTables"
          :price-keys="pricesSetting.price_keys"
          :is-time-slot-selectable="!usePriceTable"
          :car-classes="carClasses"
          :selected-time-slot="selectedTimeSlot"
          :selected-time-slot-type="pricesSetting.time_slot_type"
          :time-slots="timeSlots"
          :cancellation-fees="cancellationFees"
          :standard-options="standardOptions"
          :selectable-options="selectableOptions"
          @add-price-row="addBasicTablePriceRow"
          @set-time-slot-id="setTimeSlotId"
          @update-price-table="setBasicTable"
          @update-price-row="setBasicTablePriceRowByIndex"
          @delete-price-row="removeBasicTablePriceRow"
          @clone-price-row="cloneBasicTablePriceRow"
        )
        .form-row
          .form-row-label
            FormFieldLabel(
              :title="$t('company_system.rate_plan_settings.rate_plan.details.car_class_settings.special_rate')"
              required
            )
          .form-row-field
            .radio.flex-row
              AppRadioButton(
                :disabled="specialRateDisabled"
                :value="!specialRateDisabled && pricesSetting.special_rates"
                @change="switchSpecialRatesMode"
              )
              .label(:class="{ disabled: specialRateDisabled }")
                span {{ $t('company_system.rate_plan_settings.rate_plan.details.car_class_settings.set_special_rates') }}
            .radio.flex-row
              AppRadioButton(
                :disabled="specialRateDisabled"
                :value="!specialRateDisabled && !pricesSetting.special_rates"
                @change="switchSpecialRatesMode"
              )
              .label(:class="{ disabled: specialRateDisabled }")
                span {{ $t('company_system.rate_plan_settings.rate_plan.details.car_class_settings.no_special_rates') }}
            BFormInvalidFeedback(
              v-if="hasNoSpecialTablesError"
              :state="false"
            )
              | {{ $t('company_system.rate_plan_settings.rate_plan.details.car_class_settings.at_least_1_special_period') }}
        template(v-if="!specialRateDisabled && pricesSetting.special_rates")
          template(v-for="(priceTable, index) in pricesSetting.special_tables")
            .form-row
              .form-row-label
                FormFieldLabel(
                  :title="$t('company_system.rate_plan_settings.rate_plan.details.car_class_settings.special_rate_number', { number: index + 1 })"
                )
            SpecialPriceTable(
              :key="`special-table-${index}`"
              :price-table="priceTable"
              :price-tables="priceTables"
              :car-classes="basicTableCarClasses"
              :price-keys="pricesSetting.price_keys"
              :selected-time-slot-type="pricesSetting.time_slot_type"
              @add-special-table-price-row="addNewSpecialTablePriceRow(index, $event)"
              @change-special-table="updateSpecialTable(index, $event)"
              @update-special-table-price-row="updateSpecialTablePriceRow(index, $event)"
              @delete-special-table-price-row="deleteSpecialTablePriceRow(index, $event)"
              @clone-special-table-price-row="cloneSpecialTablePriceRow(index, $event)"
              @set-special-table-basic-price-rows="setSpecialTableBasicPriceRows(index)"
              @reset-special-table-price-rows="resetSpecialTablePriceRows(index, $event)"
            )
          AppIconButton.add-special-rate-action(
            icon="plus-circle"
            title="company_system.rate_plan_settings.rate_plan.details.car_class_settings.add_special_rate"
            @click="addSpecialTable"
          )
    FormActionBar(
      delete-enabled
      @delete="$emit('remove-plan')"
      @save="handleSave"
    )
</template>

<script>
  const CAR_CLASSES_SERIALIZER_VIEW = "table"

  // misc
  import { SERIALIZER_VIEW_EXTENDED } from "@/config/constants"
  import { DAY_TIME_SLOT_TYPE } from "@/pages/CompanySystem/BasicSettings/constants"
  import { get, some, map, find, range, filter, isEmpty, isEqual } from "lodash-es"
  import { bus } from "@/config"

  // mixins
  import withConfirmation from "@/mixins/withConfirmation"
  import withStoreModule from "@/mixins/withStoreModule"
  import withScrollTop from "@/mixins/withScrollTop"
  import withValidations from "@/mixins/withValidations"

  // store modules
  import priceSettingsModule from "@/config/store/company_system/rate_plan_settings/rate_plan/prices_settings"
  import priceTableModule from "@/config/store/company_system/rate_plan_settings/price_table"
  import carClassSettingsModule from "@/config/store/company_system/car_class_settings/car_classes"
  import timeSlotsModule from "@/config/store/company_system/basic_settings/time_slots"
  import cancellationFeesModule from "@/config/store/company_system/basic_settings/cancellation_fees"
  import optionsModule from "@/config/store/company_system/basic_settings/options"
  import planCommonModule from "@/config/store/company_system/rate_plan_settings/rate_plan/common_settings"

  const validationsMixin = withValidations(({ required }) => ({
    pricesSetting: {
      special_rates: { required },
      special_tables: {
        minLength: (value, self) => !self.special_rates || value.length > 0
      }
    }
  }))

  const pricesSettingMixin = withStoreModule(priceSettingsModule, {
    resetState: true,
    name: "planPricesSettingSetting",
    readers: {
      pricesSetting: "item",
      initialPricesSetting: "initialItem"
    },
    mutations: {
      setDirty: "SET_DIRTY",
      resetPricesSetting: "RESET_ITEM",
      setBasicTable: "SET_BASIC_TABLE",
      addBasicTablePriceRow: "ADD_BASIC_TABLE_PRICE_ROW",
      setBasicTablePriceRowByIndex: "SET_BASIC_TABLE_PRICE_ROW_BY_INDEX",
      resetPriceSettings: "RESET_PRICE_SETTINGS",
      cloneBasicTablePriceRow: "CLONE_BASIC_TABLE_PRICE_ROW",
      removeBasicTablePriceRow: "REMOVE_BASIC_TABLE_PRICE_ROW",
      addSpecialTable: "ADD_SPECIAL_TABLE",
      setSpecialTableByIndex: "SET_SPECIAL_TABLE_BY_INDEX",
      addSpecialTablePriceRow: "ADD_SPECIAL_TABLE_PRICE_ROW",
      setSpecialTablePriceRowByIndex: "SET_SPECIAL_TABLE_PRICE_ROW_BY_INDEX",
      removeSpecialTablePriceRowItem: "REMOVE_SPECIAL_TABLE_PRICE_ROW",
      cloneSpecialTablePriceRowItem: "CLONE_SPECIAL_TABLE_PRICE_ROW",
      setSpecialTableBasicPriceRows: "SET_SPECIAL_TABLE_BASIC_PRICE_ROWS",
      resetSpecialTablePriceRows: "RESET_SPECIAL_TABLE_PRICE_ROWS"
    },
    actions: {
      fetchPricesSetting: "FETCH_ITEM",
      updatePricesSetting: "UPDATE_ITEM"
    }
  })

  const priceTableMixin = withStoreModule(priceTableModule, {
    name: "priceTablesMixin",
    readers: { priceTables: "items", priceTablesLoading: "loading" },
    actions: { fetchPriceTables: "FETCH_ITEMS" }
  })

  const carClassesMixin = withStoreModule(carClassSettingsModule, {
    name: "carClassesMixin",
    readers: { carClasses: "items", carClassesLoading: "loading" },
    actions: { fetchCarClasses: "FETCH_ITEMS" }
  })

  const timeSlotsMixin = withStoreModule(timeSlotsModule, {
    name: "timeSlots",
    readers: { timeSlots: "items", timeSlotsLoading: "loading" },
    actions: { fetchTimeSlots: "FETCH_ITEMS" }
  })

  const cancellationFeesMixin = withStoreModule(cancellationFeesModule, {
    name: "cancellationFees",
    readers: { cancellationFees: "items", cancellationFeesLoading: "loading" },
    actions: { fetchCancellationFees: "FETCH_ITEMS" }
  })

  const optionsMixin = withStoreModule(optionsModule, {
    name: "optionsMixin",
    readers: { options: "items", optionsLoading: "loading" },
    actions: { fetchOptions: "FETCH_ITEMS" }
  })

  const companiesPlanCommonSettingMixin = withStoreModule(planCommonModule, {
    name: "companiesPlanCommonSetting",
    readers: { commonSetting: "item" }
  })

  export default {
    components: {
      FormActionBar: () => import("@/pages/CompanySystem/FormActionBar"),
      CarClassCard: () => import("./CarClassCard"),
      PriceTable: () => import("./PriceTable"),
      SpecialPriceTable: () => import("./SpecialPriceTable"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppRadioButton: () => import("@/components/elements/AppRadioButton"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppDatepicker: () => import("@/components/elements/AppDatepicker")
    },

    mixins: [
      withConfirmation,
      pricesSettingMixin,
      priceTableMixin,
      carClassesMixin,
      timeSlotsMixin,
      cancellationFeesMixin,
      optionsMixin,
      companiesPlanCommonSettingMixin,
      withScrollTop,
      validationsMixin
    ],

    props: {
      planId: {
        type: Number,
        required: false
      },
      active: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        timeSlotId: null,
        usePriceTable: true
      }
    },

    watch: {
      active: {
        handler() {
          if (this.active) {
            this.resetPricesSetting()
          } else {
            this.setDirty(false)
          }
        }
      },

      usePriceTable: {
        handler() {
          this.timeSlotId = null
        }
      },

      basicTableCarClassIds(carClassIds) {
        if (!isEmpty(carClassIds)) {
          this.truncateSpecialTablePriceRows()
        }
      },

      hasUnsavedChanges(useConfirm) {
        this.setDirty(useConfirm)
      }
    },

    mounted() {
      this.fetchPriceTables({ pagination: { _disabled: true }, serializer_view: SERIALIZER_VIEW_EXTENDED })
      this.fetchCarClasses({ pagination: { _disabled: true }, serializer_view: CAR_CLASSES_SERIALIZER_VIEW })
      this.fetchTimeSlots({ pagination: { _disabled: true }, serializer_view: SERIALIZER_VIEW_EXTENDED })
      this.fetchCancellationFees({ pagination: { _disabled: true } })
      this.fetchOptions({ pagination: { _disabled: true } })
      this.fetchPricesSetting(this.planId)
    },

    computed: {
      isEdit() {
        return !!get(this.pricesSetting, "basic_table.id")
      },

      hasUnsavedChanges() {
        return !isEqual(this.pricesSetting, this.initialPricesSetting)
      },

      basicTableCarClassIds() {
        return map(get(this.pricesSetting, "basic_table.price_rows", []), "car_class_id")
      },

      basicTableCarClasses() {
        return filter(this.carClasses, ({ id }) => this.basicTableCarClassIds.includes(id))
      },

      specialRateDisabled() {
        return (
          !this.pricesSetting.time_slot_type || isEmpty(get(this.pricesSetting, "basic_table.price_rows[0].prices"))
        )
      },

      hasInvalidPriceTables({ pricesSetting: { basic_table, special_tables } }) {
        return some([basic_table.invalid, ...map(special_tables, "invalid")])
      },

      hasNoSpecialTablesError() {
        return this.$v.pricesSetting.special_tables.$error
      },

      selectedTimeSlot() {
        return find(this.timeSlots, ({ id }) => id === this.timeSlotId)
      },

      standardOptions({ commonSetting: { standard_option_ids } }) {
        return filter(this.options, ({ id }) => standard_option_ids && standard_option_ids.includes(id))
      },

      selectableOptions({ commonSetting: { selectable_option_ids } }) {
        return filter(this.options, ({ id }) => selectable_option_ids && selectable_option_ids.includes(id))
      }
    },

    methods: {
      async handleSave() {
        this.validateAttributes()
        await this.validatePriceTables()
        if (this.isInvalid || this.hasInvalidPriceTables) {
          this.$nextTick(() => {
            this.scrollTo({ target: ".is-invalid", inline: "center" })
          })
          return
        }
        this.sendRequest().then(() => {
          this.cancelValidation()
          this.cancelPriceTablesValidation()
          this.$emit("refresh-plan")
        })
      },

      async sendRequest() {
        await this.updatePricesSetting({ planId: this.planId, prices_setting: this.pricesSetting })
      },

      async validatePriceTables() {
        bus.emit("validate-basic-table")
        this.pricesSetting.special_tables.forEach((_, index) => {
          bus.emit(`validate-special-table-${index}`)
          bus.emit(`validate-special-table-${index}-price-table`)
        })
      },

      cancelPriceTablesValidation() {
        bus.emit("cancel-basic-table-validation")
        this.pricesSetting.special_tables.forEach((_, index) => {
          bus.emit(`cancel-special-table-${index}-validation`)
          bus.emit(`cancel-special-table-${index}-price-table-validation`)
        })
      },

      buildPriceKeys(timeSlot) {
        if (isEmpty(timeSlot)) {
          return []
        }

        if (timeSlot.type === DAY_TIME_SLOT_TYPE) {
          const basicKeys = range(1, timeSlot.until_day + 1).map(day => `day_${day}`)
          return [...basicKeys, "day_after"]
        } else {
          const hours = map(timeSlot.hours, "value")
          const basicKeys = hours.map(hour => `hours_${hour}`)
          return [...basicKeys, "hour_after", "day_after"]
        }
      },

      setTimeSlotId(timeSlotId) {
        this.timeSlotId = timeSlotId
        this.pricesSetting.time_slot_type = this.selectedTimeSlot?.type
        this.pricesSetting.price_keys = this.buildPriceKeys(this.selectedTimeSlot)
      },

      clearAll() {
        this.$confirm({
          title: this.$t("company_system.rate_plan_settings.rate_plan.details.car_class_settings.reset_confirmation"),
          resolve: {
            handler: () => {
              this.timeSlotId = null
              this.resetPriceSettings()
            }
          }
        })
      },

      truncateSpecialTablePriceRows() {
        this.pricesSetting.special_tables.forEach(({ price_rows, ...rest }, index) => {
          this.updateSpecialTable(index, {
            ...rest,
            price_rows: filter(price_rows, ({ car_class_id }) => this.basicTableCarClassIds.includes(car_class_id))
          })
        })
      },

      updateSpecialTable(index, item) {
        this.setSpecialTableByIndex({ index, item })
      },

      addNewSpecialTablePriceRow(tableIndex, item) {
        this.addSpecialTablePriceRow({ tableIndex, item })
      },

      cloneSpecialTablePriceRow(tableIndex, rowIndex) {
        this.cloneSpecialTablePriceRowItem({ tableIndex, rowIndex })
      },

      deleteSpecialTablePriceRow(tableIndex, rowIndex) {
        this.removeSpecialTablePriceRowItem({ tableIndex, rowIndex })
      },

      updateSpecialTablePriceRow(tableIndex, row) {
        this.setSpecialTablePriceRowByIndex({ tableIndex, row })
      },

      switchBasicChargeMode() {
        this.usePriceTable = !this.usePriceTable
      },

      switchSpecialRatesMode() {
        this.pricesSetting.special_rates = !this.pricesSetting.special_rates
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/mixins/common.sass"
  @import "@/assets/styles/variables.sass"

  .car-class-settings-form
    overflow: visible
    margin-bottom: 20px

    .flex-row
      display: flex
      justify-content: left

    .title
      height: 40px
      border-left: 13px solid $default-purple-light
      padding: 10px
      font-weight: 700
      font-size: 16px

    .form
      padding: 10px
      overflow-y: visible

      &-row
        display: flex
        flex-direction: column
        margin-top: 20px

        &.card-deck
          padding: 20px
          background-color: $default-gray-light
          min-height: 200px
          margin-right: 20px
          width: auto

          .car-classes-bar
            margin-top: 20px
            align-items: center
            width: 100%
            overflow-x: visible

            &-item
              margin-right: 20px

            .add-car-class
              display: flex
              flex-direction: column
              width: 110px
              color: $default-purple
              font-weight: 100
              font-size: 10px

              .label
                margin: 0 auto
                text-align: center

              &-action
                +icon-button($default-purple)
                width: 60px
                height: 60px
                margin: 5px auto

                ::v-deep
                  svg
                    width: 60px
                    height: 60px

        .clear
          width: 120px
          border-color: $default-purple
          color: $default-purple

        .special-rates
          .fee-types-label,
          .price-table-label,
          .car-classes-bar
            margin-top: 20px

        .add-special-rate-action
          margin-top: 20px
          margin-left: -5px
          width: 136px
          background-color: $default-white
          border-color: $default-turquoise-medium

          ::v-deep
            svg
              path
                fill: $default-turquoise-medium
            .title
              color: $default-turquoise-medium

        &-label
          font-style: normal
          font-weight: 200
          font-size: 13px
          line-height: 19px

          span.required
            margin-left: 10px
            font-size: 12px
            color: $default-red

        &-field
          margin-top: 5px
          font-weight: 400
          font-size: 16px

          .app-select
            padding: 0

          .label
            font-size: 13px
            margin-left: 10px

          .radio
            margin-bottom: 10px

            .label
              &.disabled
                color: $default-gray
</style>
